import Vue from 'vue';
import _ from 'lodash';
import {ActionTree, GetterTree, MutationTree} from 'vuex';
import {RootState} from '~/store/index'
import Box from '~/entities/Box';
import Trip from '~/entities/Trip';
import { RatesArray } from '~/entities/Rates';
import { Details } from '~/entities/Details';
import * as Sentry from "@sentry/vue";
import LandedCostResponse from '~/entities/LandedCostResponse';
import Holidays from '~/entities/Holidays';
import * as StoreHelper from '~/entities/StoreHelper';

import { DetailsSource } from '~/entities/AVS';
interface DetailsI {
  from: Details,
  to: Details
}

class AddressRequest {
  address1:string;
  address2:string;
  postalCode:string;
  city:string;
  state:string;
  company:string;
  country:string;
  triggerType:number | null;
  public constructor(init?:Partial<AddressRequest>) {
    Object.assign(this, init);
    this.address1 = '';
    this.address2 = '';
    this.postalCode = '';
    this.city = '';
    this.state = '';
    this.country = '';
    this.company = '';
    this.triggerType = null

  }
}

type RatesData = RatesArray[];

export type ShipState = ReturnType<typeof state>

export const state = () => ({
  //contents
  stages: {
    trips: [] as Trip[],
    details: [] as DetailsI[]
  },
  contents: {
    boxes: [] as Array<Box>,
    descriptions: [] as any[]
  },
  pickupOption: [{}] as any,

  //constants
  units: null as unknown as Number,
  purpose: null as unknown as String,
  pickUpCharge: 5,

  //flow controllers
  stepName: 'Search' as string,
  stepNumber: 1 as number,
  step: { id: 1 as number, name: 'Search' as string },
  shipMode: {id: 1, label: 'One-Way'} as any,

  //rates
  rates: null as RatesData[] | null,
  ratesAreValid: {} as any,
  selectedRateSpeed: [] as any [],
  selectedRateSpeedIndex: [0] as any [],

  //fees and insurance
  fees: [{}] as any,
  premiumProtection: {coverage: 0 as number, cost: 0 as number},

  //events -- not sure if possible, but this should be in eventShipping store.
  eventInputOptions: [] as any[],
  outShip: false as boolean,
  uniqueId: null as null | number | string,
  eventCoupon: null as null | number | string, // does this do anything?

  //temporal order details
  temporalOrderIds: null as unknown as any,
});

export const getters: GetterTree <ShipState, RootState> = {
  trips : (state) => state.stages.trips as Trip[],
  getTrip: (state) => (rateId) => {
    if(state.rates){
      return state.rates[0][rateId]
    }
  },
  boxes : (state) => state.contents.boxes as Box[],
  descriptions : (state) => state.contents.descriptions,
  details : (state) => state.stages.details,
  units : (state) => state.units,
  purpose : (state) => state.purpose,
  shipMode : (state) => state.shipMode,
  pickupOption : (state) => state.pickupOption,
  rates : (state) => state.rates,
  ratesAreValid : (state) => state.ratesAreValid,
  selectedRateSpeed : (state) => state.selectedRateSpeed,
  selectedRateSpeedIndex : (state) => state.selectedRateSpeedIndex,
  stepName : (state) => state.stepName,
  stepNumber : (state) => state.stepNumber,
  premiumProtection : (state) => state.premiumProtection,
  step: (state) => state.step,
  eventInputOptions: (state) => state.eventInputOptions,
  outShip : (state) => state.outShip,

  fees : (state) => state.fees,
  temporalOrderIds : (state) => state.temporalOrderIds,
  uniqueId: (state) => state.uniqueId,
  eventCoupon: (state) => state.eventCoupon,
}

export const mutations: MutationTree <ShipState> = {
  setTrips : (state, value: Trip[]) => {
    (state.stages.trips as Trip[]) = value;
  },
  addTrip : (state, value:Trip) => {state.stages.trips.push(new Trip)},
  removeTrips : (state) => { state.stages.trips.pop() },
  removeTripByIndex : (state, index:number) => {
    const trip = state.stages.trips;
    trip.splice(index, 1);
    state.stages.trips = trip;
  },
  addDetail : (state, value:any) => {
    state.stages.details.push({from:new Details(), to:new Details()})
  },
  setDetailsOnly(state, value: any[]){
    state.stages.details = value;
  },
  setDetails(state, value: any[]){
    state.stages.details = value;
    StoreHelper.default.updateTripDetails(state.stages.trips, state.stages.details);
  },
  setTrip : (state, data: { direction: string, value: any, index:number}) => {
    state.stages.trips[data.index][data.direction] = data.value;
  },
  setDetail : (state, data: { direction: string, value: any, index:number}) => {
    if(!state.stages.details[data.index]){
      state.stages.details[data.index] = { from:new Details(), to:new Details() };
    }
    if(!state.stages.details[data.index][data.direction] || data.direction && data.value === 'clear' ){
      state.stages.details[data.index][data.direction] = new Details();
      return;
    }
    if(data.value && state.stages.details[data.index][data.direction] ){
      const merge = StoreHelper.default.mergeObjects(state.stages.details[data.index][data.direction], data.value);
      state.stages.details[data.index][data.direction] = merge;
    }else{
      state.stages.details[data.index][data.direction] = data.value;
    }
  },
  clearDetail : (state, data: { direction: string,  index:number}) => {
    if(state.stages?.details?.[data.index]?.[data.direction] && data.direction){
      state.stages.details[data.index][data.direction] = new Details();
      return;
    }
  },

  setDescriptions(state, value: any[]){
    state.contents.descriptions = value
  },
  cleanTrips: (state) =>{
    state.stages.trips = [];
  },
  setBoxes : (state, value: Array<any>) => {
    state.contents.boxes = value;
    let coverage:number = 0;
    let cost:number = 0;
    const boxes:Box[] = state.contents.boxes;
    for(let step = 0; step < boxes.length; step++){
      coverage = coverage + (boxes[step].premiumProtection ? boxes[step].premiumProtection.coverage : 0);
      cost = cost + (boxes[step].premiumProtection ? boxes[step].premiumProtection.cost : 0);
    }
    state.premiumProtection.coverage = coverage;
    state.premiumProtection.cost = cost;
    // set the fees here??
  },
  setBox : (state, data: {box:Box, index: number }) => {// unused
    state.contents.boxes[data.index] = data.box;
  },
  setBoxProperty(state, data: { name:string, value:any, index:number }){
    state.contents.boxes[data.index][data.name] = data.value;
  },
  setBoxError : (state, data: { index:number, error:string }) => {
    Vue.set(state.contents.boxes[data.index].errors, data.error,true)
  },
  cleanBoxError : (state, data: { index:number, error:string }) => {
    Vue.set(state.contents.boxes[data.index].errors, data.error,false)
  },
  cleanBoxErrors : (state,index: number ) => {
    state.contents.boxes[index].errors = {
      lengthInvalid: false,
      widthInvalid: false,
      heightInvalid: false,
      weightInvalid: false,
      dimensionalInvalid: false,
      categorySelectionInvalid: false,
      marketValueInvalid: false,
      premiumProtectionInvalid: false,
      nameSelectionInvalid: false
    }
  },
  removeBoxes :(state) => {
    state.contents.boxes.pop();
  },
  setUnits : (state, value: Number) => {
    state.units = value;
  },
  setPurpose : (state, value: String) => {
    state.purpose = value;
  },
  setShipMode : (state, value) => {
    state.shipMode = value;
  },
  setRates : (state, value) => {
    state.rates = value;
  },
  setPickUpOption : (state, value) => { // set fees here
    const index = value.index ? value.index : 0;
    if(value.selection.option.code.includes('existing')){
      value.selection.option.hasPickup = false;
    }
    if(state.rates && state.rates[0] && state.rates[0][index]){
      state.rates[0][index].pickupFee = value.selection.option.hasPickup ? state.pickUpCharge : 0;
      const options = state.pickupOption;
      state.pickupOption = [];
      options[index] = value.selection.option;
      state.pickupOption = options;
    }
    //
    const fees = state.fees;
    if( fees?.[index] ){
      fees[index].pickupFee = state.rates ? state.rates[0][index].pickupFee : 5;
    }
  },
  setRateShipDate : (state, value) => {
    const index = value.index ?? 0;
    const val = value.value ?? '';
    if( state.rates?.[0]?.[index]?.rates ){
      state.rates[0][index].rates =
        state.rates[0][index].rates.map(rate => {
          rate.ship = val;
          return rate;
        });
    }
  },
  setUPSDeliveryDate : (state, value) => {
    const index = value.index ?? 0;
    const val = value.value ?? '';
    if( state.rates?.[0]?.[index]?.rates ){
      state.rates[0][index].rates =
        state.rates[0][index].rates.map(rate => {
          rate.upsDeliver = val;
          return rate;
        });
    }
  },
  setDeliveryDate : (state, value) => {
    const index = value?.index ?? 0;
    const val = value?.value ?? '';
    if( state?.rates?.[0]?.[index]?.rates ){
      state.rates[0][index].rates =
        state.rates[0][index].rates.map(rate => {
          rate.deliver = val;
          return rate;
        });
    }
  },
  setSelectedRateSpeed : (state, value) => {
    const index = value?.index ?? 0;
    const rateIndex = value?.rateIndex ?? 0;
    try {
      if ( state.rates?.[0]?.[index]?.rates ) {
        let rates = state.rates?.[0]?.[index]?.rates;
        rates = rates.map(rate => {
          rate.selected = 0;
          return rate;
        });
        rates[rateIndex].selected = 1;
        state.selectedRateSpeed[index] = rates[rateIndex];
        state.rates[0][index].rates = rates;
        const properties = ['commodityCurrencyCode', 'commodityId', 'commodityVAT','hsCode', 'isCalculable', 'totalCommodityDutyAndTax', 'totalCommodityTaxesAndFees'];
        const allBoxesHaveProperties = state?.contents?.boxes.every(box => properties.every(prop => box.hasOwnProperty(prop)));
        if (state?.contents?.boxes && state.rates[0][index].rates[rateIndex]?.packages) {
          for (let i = 0; i < state?.contents?.boxes.length; i++) {
            if (state?.rates[0]?.[index]?.rates[rateIndex]?.packages?.[i]) {
              const box = { ...state.contents.boxes[i], ...state?.rates[0]?.[index]?.rates[rateIndex]?.packages?.[i]};
              state.contents.boxes[i] = box;
            }
          }   
        } else if (allBoxesHaveProperties) {
          for (let i = 0; i < state?.contents?.boxes.length; i++) {
            const box = state.contents.boxes[i];
            properties.forEach(prop => {
              if (box.hasOwnProperty(prop)) {
                delete box[prop];
              }
            });
            state.contents.boxes[i] = box;
          }  
        }
      }
    } catch (error) {
      console.log(error);
      
    }
  },
  setSelectedRateSpeedIndex : (state, value) => {
    state.selectedRateSpeedIndex[value.index as number] = value.rateIndex as number;
  },
  setDefaultRateSpeed : (state) => {
    //state.buckForBikes = null; // why is this here? makes no sense.
    state.fees = state.fees ? state.fees : [];
    if(state.rates && state.rates[0]){
      for(let step = 0; step < state.rates[0].length; step++){
        state.rates[0][step].rates[0].selected = 1;
        state.selectedRateSpeed[step] = 0;
        state.selectedRateSpeedIndex[step as number] = 0;
        if (!state.fees[step]) {
          state.fees.push({
            stage: step,
            baseFee: 0,
            customsFee: 0,
            protectionFee: 0,
            pickupFee: 0,
            residentialFee: 0,
            hazMatFee: 0
          });
        }
      }
    }else{
      console.log('no rates :(');
    }
  },
  setFees : (state, value) => {
    state.fees = value;
  },
  setStepName : (state, value: string) => {
    state.stepName = value;
  },
  setStepNumber : (state, value: number) => {
    state.stepNumber = value;
  },
  setRatesAreValid : (state, value) => {
    state.ratesAreValid = value;
  },
  setPremiumProtection : (state, value) => {
    state.premiumProtection = value;
  },

  setStep: (state, value: {id: number, name: string}) => {
    state.step = value;
  },
  setInputOptions: (state, value: any[]) => {
    state.eventInputOptions = value;
  },
  setOutShip: (state, value: boolean) => {
    state.outShip = value;
  },
  setTemporalOrderIds: (state, value: any) => {
    state.temporalOrderIds = value;
  },

  flush: (state, caller: string) => {
    if(caller != 'bicycleshops'){
      state.stages.trips = [];
      state.stages.details = [];
    }
    state.contents.boxes = [];
    state.contents.descriptions = [];
    state.units = null as unknown as Number;
    state.purpose = null as unknown as String;
    state.shipMode = {id: 1, label: 'One-Way'};
    state.rates = null as unknown as any[];
    state.pickupOption = [{}];
    state.ratesAreValid = {};
    state.selectedRateSpeed = [];
    state.selectedRateSpeedIndex = [0];
    state.premiumProtection = {coverage: 0 as number, cost: 0 as number};
    state.fees = [{
      stage: 0,
      baseFee: 0,
      customsFee: 0,
      protectionFee: 0,
      pickupFee: 0,
      residentialFee: 0,
      hazMatFee: 0
    }];
    state.stepName = 'Search';
    state.temporalOrderIds = null;
    if(caller === 'ship'){//this needs to be called in a different flush method, one for payment.
      state.eventInputOptions = [] as any[];
    }
  },

  clearContentsOnDestroy: (state, value: any) => {
    state.contents.boxes = [];
    state.contents.descriptions = [];
  },

  setResidentialFees: (state) => {
    try{
      const fees = state.fees;
      if(state.rates && state.rates[0]){
        for(let step = 0; step < state.rates[0].length; step++){
          for(let rateStep = 0; rateStep < state.rates[0][step].rates.length; rateStep++){
            if(state.rates[0][step].rates[rateStep].selected){
              const residentialFee = state.rates[0][step].rates[rateStep].residentialFee;
              const isResidential = state.stages.details[step] && state.stages.details[step].to ? state.stages.details[step].to.isResidential : false;
              if(isResidential && fees && fees[step]){
                if(isResidential){
                  fees[step].residentialFee = residentialFee;
                }else{
                  fees[step].residentialFee = 0;
                }
              }
            }
          }
        }
      }
    }catch (e){
      console.log(e);
    }
  },
  resetRequotes : (state) => {
    if(state.stages && state.stages.details)
      for (let i = 0; i < state.stages.details.length; i++) {
        if(state.stages.details[i].from && state.stages.details[i].from.requote){
          state.stages.details[i].from.requote = false;
        }
        if(state.stages.details[i].to && state.stages.details[i].to.requote){
          state.stages.details[i].to.requote = false;
        }
      }
  },

  setUniqueId: (state, value : string) => {
    state.uniqueId = value
  },
  setEventCoupon: (state, value: string | number) => {
    state.eventCoupon = value
  },
  updateTripsFormattedAddressLabels : (state) => {
    try{
      const trips = state.stages.trips;
      for (let i = 0; i < trips.length; i++) {
        if(trips[i] && typeof trips[i].updateFormattedAddressLabels === "function"){
          trips[i].updateFormattedAddressLabels();
        }
      }
    }catch (e){
      console.log(e);
    }
  },
  updateTripsLabelsAfterAddressReplace : (state, value) => {
    try{
      const {index, resolve, type} = value;
      const trips:Trip[] = state.stages.trips;
      if(trips[index] && resolve){
        trips[index][type].reverseResolvedAddressComponents(resolve)
        trips[index][type].formatLabel(false, resolve)
      }
    }catch (e){
      console.log(e);
    }
  }
}

export const actions: ActionTree <ShipState, RootState> = {
  pushBox ({ commit }, box) {
    const boxes: Box[] = this.getters.boxes;
    commit('setBoxes', [...boxes, box]);
  },
  convertUnits ({commit}, { units, index }) {
    // this was horrible, I hate everything about it, why was js rounding up with 1 tails??? WHY?
    const arrayElem: string[] = ['length', 'height', 'width', 'weight'];
    if (units === 'cm'){
      arrayElem.forEach((element:string) => {
        const val = StoreHelper.default.roundToNearest( ((this as any).state.ship.contents.boxes[index][element] ? (this as any).state.ship.contents.boxes[index][element] - 0.006 : 0) / (element != 'weight' ? 0.39370078 : 2.20498765) );
        commit('setBoxProperty', { name:element, value:val, index })
      });
    }
    else {
      arrayElem.forEach((element:string) => {
        let val = StoreHelper.default.roundToNearest( ((this as any).state.ship.contents.boxes[index][element] ? (this as any).state.ship.contents.boxes[index][element] - 0.001 : 0) * (element != 'weight' ? 0.39370078 : 2.204602262) );
        commit('setBoxProperty', { name:element, value:val, index })
      });
    }
  },
  setStep({rootGetters, rootState, dispatch, commit }, value: string | number) {
    const prevStep = this.getters['ship/stepNumber'];
    if (value == 1 || value == 'Search'){
      commit('setStepNumber', 1);
      commit('setStepName', 'Search');
    } else
    if (value == 2 || value ==  'Costs'){
      commit('setStepNumber', 2);
      commit('setStepName', 'Costs');
    } else
    if (value == 3 || value ==  'Details'){
      commit('setStepNumber', 3);
      commit('setStepName', 'Details');
    } else
    if (value == 4 || value ==  'Contents'){
      commit('setStepNumber', 4);
      commit('setStepName', 'Contents');
    } else
    if (value == 5 || value ==  'Payment') {
      commit('setStepNumber', 5);
      commit('setStepName', 'Payment');
    } else {
      console.log(`unknown step: `+value);
    }
    if(!this.getters['loading']){ //this is a bit rudimentary but for now it is the quickest way to prevent update on resume. maybe change to its own var or pass a flag in the value
      const nextStep = this.getters['ship/stepNumber'];
      if(!this.getters['ship/outShip']){
        dispatch('user/updateSearch', {previousStep: prevStep, nextStep: nextStep, active: true}, {root:true});
      }
    }
  },

  //rates
  async getRates({commit, getters, rootGetters}) {
    const postResponse = await Promise.all([
      this.$axios.$post(`/api/Rates`, StoreHelper.default.getRequestRatesBodyObject(getters, rootGetters)),
    ]);
    if(postResponse){
      return await Promise.all([
        this.$axios.$get(`/api/Rates?id=` + (postResponse[0] && (postResponse[0] as any).id ? (postResponse[0] as any).id : '')),
      ]);
    }else{
      return false;
    }
  },
  async requestRates({rootGetters, dispatch, commit, getters }, data){
    const details:any[] = getters.details;
    try{
      commit('setLoading', true, {root:true});
      commit('ship/setRatesAreValid', {event: {}, valid: true}, {root:true});
      const ratesResponse:RatesData = await dispatch('getRates');
      if(ratesResponse && StoreHelper.default.validateUPSRates(ratesResponse)){
        const filteredRatesResponse = StoreHelper.default.filterRatesResponse(ratesResponse);
        setTimeout(async () => {
          commit('setRates', filteredRatesResponse);
          commit('setLoading', false, {root:true});
          for (let i = 0; i < details.length; i++) {
            if(details[i]?.to.requote === true || details[i]?.from.requote === true){
              commit('ship/setRatesAreValid', {event: {results: 'LocationChangedRequote'}, valid: false}, {root:true});
              return false;
            }
          }
          dispatch('setStep', data.stepNumber);
        }, 1500);
        return filteredRatesResponse;
      }else{
        if(ratesResponse){//AlertFedExNoResults
          commit('setLoading', false, {root:true});
          commit('ship/setRatesAreValid', {event: {results: ratesResponse}, valid: false}, {root:true});
          return false;
        }else{//AlertFedExTimeout
          commit('setLoading', false, {root:true});
          commit('ship/setRatesAreValid', {event: {results: false}, valid: false}, {root:true});
          return false;
        }
      }
    } catch (e){//AlertFedExFailed or AlertFedExCanadaNoResults
      console.log(e);
      commit('setLoading', false, {root:true});
      commit('ship/setRatesAreValid', {event: e, valid: false}, {root:true});
      return false;
    }
  },

  //landedCost
  async getLandedCost({commit, getters}){
    try{
      const trips =  _.cloneDeep(getters.trips);
      const rates =  _.cloneDeep(getters.rates);
      const fees = _.cloneDeep(getters.fees);
      for (let i = 0; i < trips.length; i++) {
        if(fees && fees.length && fees[i]){
          fees[i].landedCost = [];
        }else{
          fees[i] = {
            stage: 0,
            baseFee: 0,
            customsFee: 0,
            protectionFee: 0,
            pickupFee: 0,
            residentialFee: 0,
            hazMatFee: 0,
            landedCost: []
          }
        }
        for(let j = 0; j < (rates && rates[0] && rates[0][i] ? rates[0][i].rates.length : 1); j++){
          const requestBodyObject = StoreHelper.default.getRequestLandedCostBodyObject(getters, i, j);
          if(requestBodyObject === false)
            continue;
          try{
            const response:LandedCostResponse = await this.$axios.$post(`/api/LandedCost/get-landed-cost`, requestBodyObject);
            if(response && response.success && response?.clearanceFee <= 10000){
              fees[i].landedCost.push(response as any);
            }else{
              fees[i].landedCost.push({success:false});
            }
            if (response.packages) {
              let rate = {...rates[0][i].rates[j], packages:response.packages};
              rates[0][i].rates[j] = rate;
              commit('setRates', rates);
            }
          }catch (ie){
            fees[i].landedCost.push({success:false});
          }
        }
      }
      commit('setFees', fees);
      return true;
    }catch (e) {
      return false;
    }
  },

  //address validations and checks
  async requestAVSCheck({rootGetters, rootState, dispatch, commit }, value){
    try{
      return await (this as any).$axios.$get(`/api/Avs/` + (value ? ('?' + value) : ''));
    }catch (e){
      return false;
    }
  },
  async requestResolve({rootGetters, rootState, dispatch, commit }, value){
    try{
      const { address, originalAddress, company, type } = value
      const params = StoreHelper.default.getAddressRequestParams(address, false , type, originalAddress, company);
      let response;
      if(params && params.forLocation){
        response = await (this as any).$axios.$get(`/api/Location/resolve/` + (params.value ? ('?' + params.value) : ''));
        response.forLocation = true;
        return response;
      }else if(params && !params.withoutStreetAddress){
        response = await (this as any).$axios.$get(`/api/AddressReplace/replace/` + (params.value ? ('?' + params.value) : ''));
        response.forLocation = false;
        return response;
      }
      else return false;
    }catch (e){
      return false;
    }
  },
  async requestAddressReplace({rootGetters, rootState, dispatch, commit }, value){
    try{
      return await (this as any).$axios.$get(`/api/AddressReplace/replace/` + (value ? ('?' + value) : ''));
    }catch (e){
      return false;
    }
  },

  //Temporal order
  async saveTemporalOrder({rootGetters, rootState, dispatch, commit, getters }){
    try{
      const requestBodyObject = StoreHelper.default.getTemporalOrderBodyObject(getters, this.getters['user/session'], rootGetters, commit);
      const response = await this.$axios.$post(`/api/Order`, requestBodyObject).catch(function (error) {
        if(error.response.status == 403){
          return 403;
        }
        if(error && error.response && error.response.status == 400){
          Sentry.captureEvent({
            message: 'api/order failed: '+JSON.stringify(error.response.data),
            level: 'error', // or 'error', 'warning', 'debug', etc.
            extra: {
              customData: error.response.status,
            },
          });
        }
        if (error.response) {
          return error.response.data;
        }
        return false;
      });
      if(response == 403){
        return 403;
      }
      if(response && response.hasOwnProperty('id')){
        commit('setTemporalOrderIds', response);
        return true;
      }else{
        return false;
      }
    }catch (e) {
      console.log(e);
      return false;
    }
  },

  async logRequest({rootGetters, rootState, dispatch, commit, getters }, data) {
    try{
      const dataLog = {
        jsonContent: JSON.stringify( data && data.value ? data.value : getters ),
        userAgent: data && data.agent ? data.agent : (data ? data :''),
        step:getters.stepNumber,
        uniqueId: getters.uniqueId ? getters.uniqueId : null
      }
      const response = await (this as any).$axios.$post(`/api/log`, dataLog, {
        withCredentials: false,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem("jwt")
        },
        maxContentLength: 100000000,
        maxBodyLength: 1000000000
      }).catch(function (error) {
        console.log(error);
        return false;
      });
      if(response){
        commit('setUniqueId', response);
        return true;
      }
    }catch (e){
      return false;
    }
  },

  async editSavedAddress({commit}, data){
    try{
      const address = data
      address.country = address.country.name
      address.state = address.state.stateprov
      address.companyName = address.company ? address.company : '';
      const response = await this.$axios.$put(`/api/Address/${data.addressId}`, address);
      return response;
    }catch(e:any){
      return false;
    }
  },

  async getScheduledDates({commit}, data){
    try{
      const currentDateRaw:string =  await this.$axios.$get(`/api/Date`, {withCredentials: false});
      const currentDate = new Date(currentDateRaw);
      const month = currentDate.getMonth() + 1;
      const year = currentDate.getFullYear();
      if(currentDate){
        const response:Holidays = await this.$axios.$get(`/api/Date/schedule/US/${year}/${month}`, {withCredentials: false});
        if(response){
          const disabledDates:string[] = [];
          response.result.forEach((holiday) => {
            const date = new Date(holiday.year, holiday.month - 1, holiday.day);
            disabledDates.push(date.toISOString().split('T')[0]);
          })
          return disabledDates;
        }
      }else{
        return false;
      }
    }catch(e:any){
      return false;
    }
  },
}
