import { render, staticRenderFns } from "./ContactUsLayout.vue?vue&type=template&id=b8200854&"
var script = {}
import style0 from "./ContactUsLayout.vue?vue&type=style&index=0&id=b8200854&prod&lang=postcss&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {TopContent: require('C:/inetpub/wwwroot/bike-flights/components/TopContent.vue').default,Navbar: require('C:/inetpub/wwwroot/bike-flights/components/Navbar.vue').default,CookieBanner: require('C:/inetpub/wwwroot/bike-flights/components/CookieBanner.vue').default,FooterContactUs: require('C:/inetpub/wwwroot/bike-flights/components/FooterContactUs.vue').default})
